.instructions {
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-top: 2vw;
}

.instructions h2 {
  color: #00447e;
  font-weight: bolder;
  font-size: 7vw;
}

.instructions h3 {
  font-size: 4.5vw;
}

.instructions-container {
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.instruction {
  display: flex;
  flex-direction: row;
  text-align: start;
  margin-bottom: 4vw;
  border-radius: 20px;
}

.instruction .images {
  margin-left: 0.5rem;
  margin-right: 10vw;
  width: 44vw;
  height: 25vw;
  min-width: 30vw;
}

.instruction .numbers {
  position: absolute;
  width: 13vw;
  margin-top: -5vw;
  margin-left: 30vw;
}

.instruction-info {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.instruction-info h4 {
  font-family: "Roboto", sans-serif;
  font-size: 3.9vw;
  color: #022e50;
  font-weight: bold;
}

.instruction-info p {
  font-family: "Pontano-sans", sans-serif;
  font-size: 3.6vw;
  color: #022e50;
}

@media screen and (min-width: 1001px) {
  .instructions h2 {
    font-size: 3vw;
  }

  .instructions h3 {
    font-size: 1.5vw;
    font-weight: 400;
  }

  .instructions-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .instruction {
    width: 50%;
    flex-direction: column;
    align-items: center;
  }

  .instruction .images {
    width: 15vw;
    height: 15vw;
    margin-left: 13vw;
  }

  .instruction .numbers {
    width: 7vw;
    margin-top: -3vw;
    margin-left: -15vw;
  }

  .instruction-info {
    width: 75%;
    margin-left: auto;
    text-align: center;
  }

  .instruction-info h4 {
    margin-top: 2vw;
    font-size: 2vw;
    text-align: center;
    width: 80%;
  }

  .instruction-info p {
    font-size: 1.5vw;
    width: 80%;
  }
}
