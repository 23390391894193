.popUpForm-message {
    position: absolute;
    width: 80%;
}

.popUpForm-title {
    margin-left: -12vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: #CBCACA;
    width: 85vw;
    border-radius: 6px;
    padding: 1rem;
    margin-top: 20vw;
    font-family: 'Poppins', sans-serif;
    filter: drop-shadow(2px 3px 4px rgb(0, 0, 0, .5));
    z-index: 99999;
}


.popUpForm h3 {
    font-size: 4vw;
    font-weight: bold;
    color: #022E50;
    text-align: center;
}

.popUpForm {
    width: 100%;
}

.popUpForm .contact-form {
    padding: 1rem;
}

.popUpForm input,
.popUpForm select {
    height: 8vw;
    font-size: 4vw;
    border: 0;
}

.popUpForm ::placeholder {
    font-size: 4vw;
}


.popUpForm label {
    font-size: 4vw;
}

.popUpForm button[type=submit] {

    font-size: 4.5vw;
    font-weight: 600;
    height: 6vh;
}

.popUpForm .popup {
    width: 80%;
    height: 50%;
    font-size: 4.5vw;
}

.popUpForm .conditions {
    justify-self: start;
}

.popUpForm label.checkLabel {
    margin-top: 2rem;
    width: 100%;
}

.popUpForm input[type=checkbox] {
    margin-left: 25%;
    margin-top: 2rem;
    width: 40%;
}

@media screen and (min-width:1001px) {


    .popUpForm-message {
        width: 90%;
    }

    .popUpForm-title {
        margin-left: auto;
        margin-right: auto;
        width: 60vw;
        margin-top: 0;
    }

    .popUpForm h3 {
        font-size: 1.5vw;
    }


    .popUpForm input,
    .popUpForm select {
        height: 2.8vw;
        font-size: .8vw;
    }

    .popUpForm ::placeholder {
        font-size: .9vw;
    }


    .popUpForm label {
        font-size: 1vw;
    }

    .popUpForm button[type=submit] {

        font-size: 1.1vw;
    }

    .popUpForm .popup {
        width: 93%;
        font-size: 1.2vw;
    }
}