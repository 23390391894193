input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}
.we-adapt {
  display: none;
}

.background-brightness {
  position: fixed;
  z-index: 9999999;
  left: 20vw;
  top: 7vw;
}

.fullPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 18vw;
  font-family: "Roboto", "Poppins", sans-serif;
  background-color: #ffffff;
}

.brightness {
  filter: brightness(0.3);
}

.fullPage .top-bar {
  display: grid;
  background-color: #ffffff;
  grid-template-rows: 1fr;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  z-index: 99;
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25%));
  padding-bottom: 5vw;
}

.slider-position {
  grid-column: 1/1;
  grid-row: 2/2;
  margin-bottom: -10vw;
}

.form-position {
  grid-column: 1;
  grid-row: 3/3;
  justify-self: center;
}

.instructions-position {
  grid-column: 1;
  grid-row: 4/4;
}

.more-info-position {
  grid-column: 1;
  grid-row: 5/5;
  justify-self: center;
  margin-bottom: 8vw;
}

.services-position {
  grid-column: 1;
  grid-row: 6/6;
}

.technology-position {
  grid-column: 1;
  grid-row: 7/7;
  background-color: #eeeeee;
}

.footer-position {
  grid-column: 1;
  grid-row: 8/8;
  background-color: #006dfc;
}

.more-info-position button {
  background-color: #015daf;
  color: #ffffff;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  border: 0;
  border-radius: 50px;
  padding: 2vw 3vw 2vw 3vw;
  font-size: 3.5vw;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25));
}

.more-info-position button:active {
  margin-top: 1.8rem;
  filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
  margin-bottom: 0.7rem;
}

.interactive-position {
  display: none;
}

@media screen and (min-width: 1001px) {
  .background-brightness {
    position: fixed;
    z-index: 9999999;
  }

  .fullPage {
    grid-template-columns: 60% 40%;
    grid-template-rows: 18vw;
    background-color: #ffffff;
  }

  .brightness {
    filter: brightness(0.3);
  }

  .fullPage .top-bar {
    transition: 0.4s;
    position: fixed;
    width: 100%;
    z-index: 99;
    overflow: hidden;
    grid-column: 1/3;
    display: grid;
    grid-template-rows: 1fr;
    grid-row: 1/1;
    padding-bottom: 0.5vw;
  }

  .slider-position {
    grid-column: 1/3;
    grid-row: 2/2;
    margin-bottom: 0vw;
  }

  .form-position {
    grid-column: 1/2;
    grid-row: 3/3;
    justify-self: start;
    /* padding-bottom: 5vw; */
  }

  .interactive-position {
    display: grid;
    grid-column: 2/3;
    grid-row: 3/3;
    background: url("../images/celphone.png") no-repeat;
    background-size: 130% 120%;
    background-position: 10% 70%;
  }

  .divider-position {
    display: grid;
    grid-column: 1/3;
    grid-row: 4/4;
    background: #122b42;
    padding-bottom: 10vw;
  }

  .instructions-position {
    grid-column: 1/3;
    grid-row: 5/5;
  }

  .more-info-position {
    grid-column: 1/3;
    grid-row: 6/6;
    margin-bottom: 2vw;
  }

  .services-position {
    grid-column: 1/3;
    grid-row: 7/7;
  }

  .technology-position {
    grid-column: 1/3;
    grid-row: 8/8;
  }

  .footer-position {
    grid-column: 1/3;
    grid-row: 9/9;
  }

  .more-info-position button {
    font-size: 2vw;
    padding: 1.5vw 2vw 1.5vw 2vw;
    margin-bottom: 4vw;
    margin-top: 1vw;
  }

  .more-info-position button:active {
    margin-top: 1.2vw;
    margin-bottom: 3.8vw;
  }
}
