.technology {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
}

.technology h2 {
    margin-top: 8vw;
    color: #00447E;
    font-weight: bolder;
}

.technology p {
    font-size: 4vw;
    padding: 1rem;
}

.technology img {
    margin-left: -10%;
    width: 130%;
}

.technology button {
    background-color: #F8DB03;
    margin-top: 1.5rem;
    margin-bottom: 10vw;
    font-weight: 500;
    border: 0;
    border-radius: 50px;
    padding: 3vw;
    font-size: 4.5vw;
    filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25));
    width: 40%;
}

.technology button:active {
    margin-top: 1.8rem;
    filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
    margin-bottom: 9vw;
}

@media screen and (min-width: 1001px) {

    .technology h2 {
        margin-top: 5vw;
        font-size: 3vw;
    }

    .technology p {
        font-size: 1.5vw;
        padding: 2vw;
    }

    .technology img {
        width: 80%;
        margin-left: 0;
    }

    .technology button {
        font-size: 1.5vw;
        padding: 1vw;
        width: 20%;
        margin-top: -5vw;
        margin-bottom: 5vw;
    }

    .technology button:active {
        margin-top: -5vw;
        margin-bottom: 5vw;
    }

}