a,
div,
button,
[type="button"] {
  -webkit-appearance: none;
}

.floating {
  display: block;
  margin-left: auto;
  grid-column: 2/3;
  grid-row: auto;
  margin-top: 4vw;
  margin-right: 7%;
  /* padding: 1.5vw; */
}

.floating-button {
  display: grid;
  grid-template-columns: 10% 90%;
  position: relative;
  background-color: #015daf;
  border-radius: 50px;
  height: 12vw;
  width: 47vw;
  justify-content: flex-end;
  text-align: center;
  align-content: center;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  cursor: pointer;
  z-index: 100000;
}

.floating-button a {
  position: relative;
  color: #ffffff;
  font-size: 4.1vw;
  text-align: center;
  text-decoration: none;
  align-self: center;
}

.floating-button a:hover {
  color: #ffffff;
  text-decoration: none;
}

.phoneIcon {
  display: grid;
  width: 9vw;
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  margin-left: 50%;
}

.NewPhoneIcon {
  display: grid;
  width: 9vw;
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  margin-left: 50%;
}

@media screen and (min-width: 1001px) {
  .floating {
    padding: 0vw;
    margin-left: auto;
    margin-top: 0vw;
  }

  .floating-button {
    width: 20vw;
    height: 3vw;
    right: 5%;
    top: 1vw;
  }

  .floating-button a {
    font-size: 1.3vw;
  }

  .phoneIcon {
    width: 2vw;
  }

  .NewPhoneIcon {
    width: 3vw;
  }
}
