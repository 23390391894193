.services {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.services h2 {
  display: inline;
  font-size: 7vw;
  font-weight: bolder;
  color: #00447e;
}

.services h3 {
  font-size: 4.5vw;
  margin-top: 1rem;
}

.items-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.item {
  display: flex;
  flex-direction: row-reverse;
  text-align: start;
  margin-bottom: 1rem;
  border-radius: 20px;
}
.item:nth-child(1) {
  background-color: #006dfc;
}
.item:nth-child(2) {
  background-color: #005dd8;
}
.item:nth-child(3) {
  background-color: #0252bb;
}
.item:nth-child(4) {
  background-color: #0148a4;
}
.item img{
  display: none;
}
.item-info {
  margin-right: 1rem;
  margin-left: 1rem;
}

.item-info h3 {
  font-family: "Roboto", sans-serif;
  font-size: 4.3vw;
  color: #ffffff;
  font-weight: bold;
}

.item-info p {
  font-size: 3.6vw;
  color: #ffffff;
}

@media screen and (min-width: 1001px) {
  .services h2 {
    font-size: 3vw;
  }

  .services h3 {
    font-size: 1.5vw;
    font-weight: 400;
  }

  .items-container {
    flex-direction: column;
    justify-content: space-around;
    height: 35vw;
    flex-wrap: wrap;
  }

  .item {
    display: flex;
    flex-direction: row-reverse;
    text-align: start;
    margin-bottom: 1rem;
    width: 48%;
    height: 45%;
    padding-left: 1rem;
  }

  .item img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 7vw;
    margin-bottom: 3vw;
    margin-right: 1vw;
  }

  .item-info h3 {
    font-weight: bold;
    font-size: 2vw;
    margin-bottom: 1rem;
  }

  .item-info p {
    font-size: 1.5vw;
  }

  .service-footer p {
    padding: 0.5rem;
    font-size: 2vw;
    font-weight: 500;
  }

  .service-footer img {
    min-width: 10vw;
  }

  .service-footer button {
    font-size: 2.5vw;
    width: 50%;
    margin-bottom: 5rem;
  }

  .service-footer button[type="button"]:active {
    margin-bottom: 4.7rem;
  }
}
