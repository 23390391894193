.whatsapp {
  position: fixed;
  bottom: 5vw;
  right: 2vw;
}
.whatsapp img {
  width: 15vw;
}

@media screen and (min-width: 1001px) {
  .whatsapp {
    position: fixed;
    bottom: 5vw;
    right: 2vw;
  }
  .whatsapp img {
    width: 5vw;
  }
}
