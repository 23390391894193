.slider-title {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.3fr 4fr;
  font-family: "Poppins", sans-serif;
}
.slider-services-title,
.slider-title h2 {
  text-align: center;
  font-weight: 500;
  grid-column: 1/1;
  grid-row: 1/1;
  background: rgb(255, 184, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 184, 0, 1) 0%,
    rgba(255, 234, 0, 1) 100%
  );
  font-size: 4.5vw;
  padding-top: 9vw;
  margin-top: -2vw;
}

.slider-title h3 {
  text-align: center;
  font-size: 4vw;
  grid-column: 1/1;
  grid-row: 1/1;
  margin-top: 12vw;
  color: #000000;
  margin-bottom: 1vw;
}

.slider-products-title {
  margin-top: 2vw;
  font-size: 4.5vw;
}

.carousel {
  grid-column: 1/1;
  grid-row: 2/2;
  width: 100%;
  /* margin-top: -6%; */
  margin-top: 3%;
}

.carousel-indicators li {
  display: none;
}

@media screen and (min-width: 1001px) {

  .slider-title {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.9fr 1fr;
    font-family: "Poppins", sans-serif;
  }

.slider-services-title,
.slider-title h2 {
    display: none;
  }

  .slider-title h3 {
    margin-top: 2vw;
    font-size: 2vw;
    font-weight: bold;
    max-width: max-content;
    align-self: center;
    cursor: pointer;
  }

  .slider-title h3:hover {
    color: #305da9;
    transform: scale(1.1);
  }

  .carousel {
    width: 100%;
    align-self: center;
    margin-top: -9vw;
  }
}
