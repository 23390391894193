.top-indications {
    position: relative;
    background-color: #E5E5E5;
    color: #000000;
    width: 100%;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 4vw;
    z-index: 9999999;
    text-align: center;
    grid-column: 1/3;
}

.top-indications-no-display {
    display: none;
}

.top-indications img {
    position: absolute;
    width: 5vw;
    right: 2vw;
}

@media screen and (min-width: 1001px) {

    .top-indications {
        height: 2vw;
        font-size: 1.5vw;
    }
    .top-indications img{
        width: 2vw;
    }

}