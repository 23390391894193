.security {
    font-family: 'Poppins', sans-serif;
    margin-left: -15vw;
}

.security h2 {
    font-weight: 700;
    margin-top: 5vw;
    width: 50%;
}

.security span {
    color: #0061A5
}

.security p {
    font-size: 1.3vw;
    margin-top: 2vw;
    width: 50%;
}

.security-images {
    margin-top: 2vw;
}

.security-images img {
    width: 5vw;
    margin-right: 2vw;
}

@media screen and (min-width: 1001px) {
    
}