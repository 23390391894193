.footer {
    text-align: center;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
}

.footer h2 {
    margin-top: 10%;
    font-weight: bold;
    font-size: 7vw;
}


.footer ul {
    list-style: none;
    text-align: start;
    margin-left: 15vw;
    margin-bottom: 10vw;
}

.footer li img {

    width: 5vw;
    margin-right: 5vw;
}

.footer li {
    font-size: 4vw;
    margin-top: 5vw;
}

.footer a {
    text-decoration: none;
    color: inherit;
}

@media screen and (min-width: 1001px) {
    .footer h2 {
        margin-top: 5vw;
        font-size: 3vw;
    }

    .footer ul {
        margin-left: 30vw;
        margin-bottom: 5vw;
    }

    .footer li img {
        width: 2.5vw;
    }

    .footer li {
        font-size: 1.5vw;
        margin-top: 3vw;
    }
}