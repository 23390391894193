.logo {
    display: block;
    width: max-content;
    z-index: 999999;
    justify-self: left;
    grid-column: 1/2;
    grid-row: auto;
    margin-top: 5vw;
    /* padding: 1.5vw; */
    padding-left: 3vw;
  }
  
  .logo img {
    margin: auto;
    width: 24vw;
  }
  
  @media screen and (min-width: 1001px) {
    .logo {
      padding: 0.5vw;
      padding-left: 3vw;
      margin-top: 0vw;
    }
  
    .logo img {
      display: block;
      width: 9vw;
    }
  }
  