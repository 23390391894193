.topbar-container-policies {
  align-items: center;
  display: grid;
  background-color: #ffffff;
  grid-template-rows: 1fr;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  z-index: 99;
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25%));
  padding-bottom: 5vw;
  height: 10%;
}

.logo-policies {
  display: block;
  width: max-content;
  z-index: 999999;
  justify-self: left;
  grid-column: 1/2;
  grid-row: auto;
  margin-top: 3.5%;
  padding-left: 5vw;
}

.logo-policies img {
  margin: auto;
  width: 23%;
}

@media screen and (min-width: 377px){

  .logo-policies {
    margin-top: 3%;
  }

  .logo-policies img {
    width: 30%;
    margin-left: 1vw;
    margin-top: 0%;
  }
}

@media screen and (min-width: 411px){

  .logo-policies {
    margin-top: 4%;
  }
}

@media screen and (min-width: 539px){

  .logo-policies {
    margin-top: 3%;
  }
}

@media screen and (min-width: 767px){

  .logo-policies {
    margin-top: 3%;
  }
}

@media screen and (min-width: 819px){

  .logo-policies {
    margin-top: 3%;
  }

  .logo-policies img {
    width: 30%;
    margin-top: -2%;
  }
}

@media screen and (min-width: 960px){

  .logo-policies {
    margin-top: 3%;
  }
}

@media screen and (min-width: 1001px) {

  .logo-policies {
    margin-top: 1.5%;
  }

  .logo-policies img {
    width: 30%;
    margin-top: 0%;
  }
}

@media screen and (min-width: 1279px) {

  .logo-policies {
    margin-top: 1.5%;
  }

  .logo-policies img {
    width: 30%;
    margin-top: -2%;
  }
}
